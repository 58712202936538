import {
  CardsRow,
  CouponsList,
  FiltersBar,
  FiltersBarInput,
  FiltersBarInputDate,
  FiltersBarSelect,
  IconBarcode16,
  Pagination,
  Wrapper,
  Form,
  useInputProps,
} from "@royalcanin-be-partner-portal/ui-kit";
import { Card } from "@royalcanin-be-partner-portal/ui-kit/src/Card";
import React, { useState } from "react";
import { Col, Row } from "react-styled-flexboxgrid";
import { Layout } from "../../components/Layout";
import { CheckPermission } from "../../components/CheckPermission";
import { parse } from "query-string";
import { RouteComponentProps } from "@reach/router";
import { subDays, format } from "date-fns/esm";
import { IFilters, messages } from "./";
import { useCoupons, useCouponsActions } from "../../lib/hooks/useCoupons";
import { useIntl } from "react-intl";
import { withPage } from "../../lib/withPage";

interface IFiltersList extends IFilters {
  barcode: string;
}

const CouponsListPageContent = ({ location }: RouteComponentProps) => {
  const { formatMessage } = useIntl();
  const filtersFromUrl: Partial<IFilters> = parse(location?.search || "");
  const [filters, setFilters] = useState<IFiltersList>({
    datein:
      (filtersFromUrl.datein && new Date(filtersFromUrl.datein as string)) ||
      subDays(new Date(), 30),
    dateout:
      (filtersFromUrl.dateout && new Date(filtersFromUrl.dateout as string)) ||
      new Date(),
    dealref: filtersFromUrl.dealref || "",
    barcode: "",
  });
  const [currentPage, setPage] = useState(1);

  const filtersParams = {
    datein: filters.datein
      ? format(new Date(filters.datein), "yyyy-MM-dd")
      : undefined,
    dateout: filters.dateout
      ? format(new Date(filters.dateout), "yyyy-MM-dd")
      : undefined,
  };
  const { items: coupons, loading, pageInfo } = useCoupons({
    ...filtersParams,
    dealref: filters.dealref || undefined,
    barcode: filters.barcode || undefined,
    page: currentPage,
    limit: 10,
  });
  const { items: actions, loading: loadingActions } = useCouponsActions(
    filtersParams,
  );

  return (
    <Wrapper>
      <Form<IFiltersList>
        loading={loadingActions || loading}
        initialValues={{
          ...filters,
        }}
        fieldLabels={{
          datein: formatMessage(messages.formLabelFrom),
          dateout: formatMessage(messages.formLabelTo),
          dealref: formatMessage(messages.actionPlaceholder),
        }}
        onSubmit={(values: IFiltersList) => {
          setFilters(values);
        }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FiltersBar buttonSubmitFilter>
              <FiltersBarInputDate
                {...useInputProps({
                  name: "datein",
                })}
              />
              <FiltersBarInputDate
                {...useInputProps({
                  name: "dateout",
                })}
              />
              <FiltersBarSelect
                items={[
                  {
                    label: formatMessage(messages.allActions),
                    value: "",
                  },
                  ...actions.map(({ ref, name }) => ({
                    label:
                      (name && (name !== ref ? `${name} (${ref})` : name)) ||
                      ref,
                    value: ref,
                  })),
                ]}
                {...useInputProps({
                  name: "dealref",
                })}
                displayEmpty
              />
              <FiltersBarInput
                {...useInputProps({
                  name: "barcode",
                })}
                IconComponent={IconBarcode16}
                placeholder={formatMessage(messages.barcodePlaceholder)}
              />
            </FiltersBar>
          </form>
        )}
      />

      <CardsRow>
        <Row>
          <Col xs={12}>
            <Card>
              <CouponsList thead coupons={coupons} loading={loading} />
            </Card>
          </Col>
        </Row>
      </CardsRow>
      <Pagination
        value={currentPage}
        pages={
          pageInfo && pageInfo.limit
            ? Math.ceil(pageInfo.count / pageInfo.limit)
            : undefined
        }
        onChange={page => {
          setPage(page);
        }}
        disabled={loading}
      />
    </Wrapper>
  );
};

const CouponsListPage = (props: RouteComponentProps) => (
  <Layout>
    <CheckPermission permissions={"coupons"} redirect>
      <CouponsListPageContent {...props} />
    </CheckPermission>
  </Layout>
);

export default withPage(CouponsListPage);
