import { useUserToken } from "@igloo-be-omnipartners/hooks";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/client";
import { Coupons, CouponsVariables } from "./__generated__/Coupons";
import {
  CouponsActions,
  CouponsActionsVariables,
} from "./__generated__/CouponsActions";
import {
  CouponsScanBarcode,
  CouponsScanBarcodeVariables,
} from "./__generated__/CouponsScanBarcode";

const CouponsQuery = gql`
  query Coupons(
    $token: String!
    $datein: String
    $dateout: String
    $dealref: String
    $barcode: String
    $page: Float
    $limit: Float
  ) {
    coupons(
      token: $token
      datein: $datein
      dateout: $dateout
      dealref: $dealref
      barcode: $barcode
      page: $page
      limit: $limit
    ) {
      result {
        barcode
        scanDate
        dealRef
      }
      pageInfo {
        hasNextPage
        count
        page
        limit
      }
      error {
        message
        code
      }
    }
  }
`;

export const useCoupons = ({
  datein,
  dateout,
  dealref,
  barcode,
  page,
  limit,
}: {
  datein?: string;
  dateout?: string;
  dealref?: string;
  barcode?: string;
  page?: number;
  limit?: number;
} = {}) => {
  const token = useUserToken();
  const { data, ...res } = useQuery<Coupons, CouponsVariables>(CouponsQuery, {
    skip: !token,
    variables: {
      token,
      datein,
      dateout,
      dealref,
      barcode,
      page,
      limit,
    },
  });

  return {
    ...res,
    data,
    items: data?.coupons.result || [],
    pageInfo: data?.coupons.pageInfo,
  };
};

const CouponsActionsQuery = gql`
  query CouponsActions($token: String!, $datein: String, $dateout: String) {
    couponsActions(token: $token, datein: $datein, dateout: $dateout) {
      result {
        ref
        name
        availableFrom
        availableTo
        subscriptionCount {
          redeemed
          subscribed
          inProgress
        }
      }
      error {
        message
        code
      }
    }
  }
`;

export const useCouponsActions = ({
  datein,
  dateout,
}: {
  datein?: string;
  dateout?: string;
} = {}) => {
  const token = useUserToken();
  const { data, ...res } = useQuery<CouponsActions, CouponsActionsVariables>(
    CouponsActionsQuery,
    {
      skip: !token,
      variables: {
        token,
        datein,
        dateout,
      },
    },
  );

  return {
    ...res,
    data,
    items: data?.couponsActions.result || [],
  };
};

const CouponsScanBarcodeMutation = gql`
  mutation CouponsScanBarcode($token: String!, $barcode: String!) {
    couponsScanBarcode(token: $token, barcode: $barcode) {
      result {
        user_guid
      }
      error {
        code
        message
        validationErrors {
          field
          errors {
            validator
            message
          }
        }
      }
    }
  }
`;

export const useScanBarcode = () => {
  const token = useUserToken();
  const [scanBarcode, result] = useMutation<
    CouponsScanBarcode,
    CouponsScanBarcodeVariables
  >(CouponsScanBarcodeMutation);

  return {
    ...result,
    error:
      result.error ||
      (result.data &&
        result.data.couponsScanBarcode &&
        result.data.couponsScanBarcode.error),
    scanBarcode: async (barcode: string) => {
      return scanBarcode({
        variables: {
          token,
          barcode,
        },
      });
    },
  };
};
