import {
  CardCouponsBarcode,
  CardCouponsDeals,
  CardCouponsLatest,
  CardCouponsStats,
  CardsRow,
  FiltersBar,
  FiltersBarInputDate,
  FiltersBarSelect,
  Wrapper,
  ColFlex,
  Form,
  useInputProps,
} from "@royalcanin-be-partner-portal/ui-kit";
import React, { useState } from "react";
import { defineMessages, useIntl } from "react-intl";
import { Row } from "react-styled-flexboxgrid";
import { Layout } from "../../components/Layout";
import { CheckPermission } from "../../components/CheckPermission";
import {
  useCoupons,
  useCouponsActions,
  useScanBarcode,
} from "../../lib/hooks/useCoupons";
import { subDays, format } from "date-fns/esm";
import { stringify } from "query-string";
import { withPage } from "../../lib/withPage";

export const messages = defineMessages({
  actionPlaceholder: {
    id: "website.coupons.actionPlaceholder",
    defaultMessage: "Actions",
  },
  allActions: {
    id: "website.coupons.allActions",
    defaultMessage: "Toutes les actions",
  },
  formLabelFrom: {
    id: "form.label.from",
    defaultMessage: "Du",
  },
  formLabelTo: {
    id: "form.label.to",
    defaultMessage: "Au",
  },
  barcodePlaceholder: {
    id: "website.coupons.barcodePlaceholder",
    defaultMessage: "Code-barres du voucher",
  },
});

export interface IFilters {
  datein: string | Date;
  dateout: string | Date;
  dealref: string | null;
}

const Coupons = () => {
  const { formatMessage } = useIntl();
  const [filters, setFilters] = useState<IFilters>({
    datein: subDays(new Date(), 30),
    dateout: new Date(),
    dealref: "",
  });
  const filtersParams = {
    datein: filters.datein
      ? format(new Date(filters.datein), "yyyy-MM-dd")
      : undefined,
    dateout: filters.dateout
      ? format(new Date(filters.dateout), "yyyy-MM-dd")
      : undefined,
  };
  const { items: coupons, loading } = useCoupons({
    ...filtersParams,
    dealref: filters.dealref || undefined,
  });
  const { items: actions, loading: loadingActions } = useCouponsActions(
    filtersParams,
  );
  const { scanBarcode } = useScanBarcode();

  return (
    <Wrapper>
      <Form<IFilters>
        loading={loadingActions || loading}
        initialValues={{
          ...filters,
        }}
        fieldLabels={{
          datein: formatMessage(messages.formLabelFrom),
          dateout: formatMessage(messages.formLabelTo),
          dealref: formatMessage(messages.actionPlaceholder),
        }}
        onSubmit={(values: IFilters) => {
          setFilters(values);
        }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <FiltersBar buttonSubmitFilter>
              <FiltersBarInputDate
                {...useInputProps({
                  name: "datein",
                })}
              />
              <FiltersBarInputDate
                {...useInputProps({
                  name: "dateout",
                })}
              />
              <FiltersBarSelect
                items={[
                  {
                    label: formatMessage(messages.allActions),
                    value: "",
                  },
                  ...actions.map(({ ref, name }) => ({
                    label:
                      (name && (name !== ref ? `${name} (${ref})` : name)) ||
                      ref,
                    value: ref,
                  })),
                ]}
                {...useInputProps({
                  name: "dealref",
                })}
                displayEmpty
              />
            </FiltersBar>
          </form>
        )}
      />
      <CardsRow>
        <Row>
          <ColFlex xs={12} sm={5} md={6} lg={4}>
            <CardCouponsDeals
              deal={
                filters.dealref
                  ? actions.find(({ ref }) => filters.dealref === ref)
                  : undefined
              }
              amount={!loadingActions ? actions.length : undefined}
            />
          </ColFlex>
          <ColFlex xs={12} sm={7} md={6} lg={8}>
            <CardCouponsBarcode
              onSubmit={async ({ barcode }) => {
                const { data } = await scanBarcode(barcode);
                const error = data?.couponsScanBarcode.error;
                if (error) {
                  return error;
                }
                return;
              }}
              loading={false}
            />
          </ColFlex>
        </Row>
      </CardsRow>
      <Row>
        <ColFlex xs={12} sm={12} md={12} lg={4}>
          <CardCouponsStats
            loading={loading}
            actions={actions}
            dealRef={filters.dealref || undefined}
          />
        </ColFlex>
        <ColFlex xs={12} sm={12} md={12} lg={8}>
          <CardCouponsLatest
            loading={loading}
            coupons={coupons}
            linkListing={`/coupons/list?${stringify({
              datein: filters.datein
                ? format(new Date(filters.datein), "yyyy-MM-dd")
                : undefined,
              dateout: filters.dateout
                ? format(new Date(filters.dateout), "yyyy-MM-dd")
                : undefined,
              dealref: filters.dealref || undefined,
            })}`}
          />
        </ColFlex>
      </Row>
    </Wrapper>
  );
};

const CouponsPage = () => (
  <Layout>
    <CheckPermission permissions={"coupons"} redirect>
      <Coupons />
    </CheckPermission>
  </Layout>
);

export default withPage(CouponsPage);
